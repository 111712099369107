export const getResortWorldData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-resorts-world-route.jpg'),
            mapLine: 'ResortWorldPath',
            venueBg:  require('@/assets/maps/resorts-world.png'),
            venue: 'Resorts World Las Vegas',
            distance: '3.5',
            singleLineVenue: false,
            threeLineVenue: true
        }
    ]
}
