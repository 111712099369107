<template>
  <div id="ResortWorld">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from '@/components/global/WhereWeAreMap'
import { getResortWorldData } from "@/data/sectionData/whereWeAreData/resortWorldMapData"

export default {
  name: "ResortWorld",
  components: {
    WhereWeAreMap
  },
  data() {
    return {
      mapData: getResortWorldData()
    }
  }
}
</script>

<style lang="scss scoped">

</style>